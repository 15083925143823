import { getTagBasedNews } from 'application/repositories/tagBasedNewsRepository';
import { getCMSHostname } from 'helpers/getCMSHostname';
import { useEffect, useState } from 'react';
import { NewsList } from 'ui/components';

/*
 *
 *
 * This component is for all sites except kongres.def.dk
 *
 *
 */

export const NewsListFeature: React.FC<Umbraco.NewsList> = ({ content }) => {
	const [news, setNews] = useState<Models.NewsCard[]>([]);

	useEffect(() => {
		const count = content?.properties?.count ? content.properties.count : 999;
		const tags = content?.properties?.tags ? content.properties.tags.map((tag) => tag.id) : null;
		// fetch news from api
		getTagBasedNews(getCMSHostname(), tags, count).then((news) => {
			setNews(news);
		});
	}, []);
	return <NewsList {...content?.properties} newsItems={news} />;
};
