import axios from 'axios';
import { getAccessTokenBrowser } from 'helpers/auth';
import { getApiUrl } from 'helpers/getApiUrl';

export type RequestHeaders = {
	hostname: string;
	Authorization?: string;
};

// Generated by https://quicktype.io

export type GetTagBasedNewsResult = Array<{
	id: number;
	name: string;
	url: string;
	properties: {
		pageSections: any[];
		pageFooter: any[];
		pageSettings: any[];
		title: string;
		description: string;
		noIndex: boolean;
		noFollow: boolean;
		breadCrumbOn: boolean;
		newsDate: string;
		updatedNewsDate: string;
		newsKicker: string;
		newsHeading: string;
		newsTeaser: string;
		newsAuthor: {
			id: number;
			culture: string;
			name: string;
			url: string;
			documentType: string;
			template: string;
			createDate: string;
			sortOrder: number;
			properties: {
				fullName: string;
				workTitle: string;
				email: string;
				bio: string;
			} | null;
			authResult?: number;
			children?: any[];
		};
		image: Umbraco.Image;
		accessableSites: Array<{
			createDate: string;
			culture: string;
			documentType: string;
			id: number;
			name: string;
			properties: null;
			sortOrder: number;
			template: string;
			url: string;
		}>;
		canonicalUrl: string;
	};
}>;

export const getTagBasedNewsAdapter = async (hostname: string, tags?: number[] | null, count = 999): Promise<GetTagBasedNewsResult> => {
	const accessToken = await getAccessTokenBrowser();
	const headers: RequestHeaders = {
		hostname,
	};
	if (accessToken) {
		headers.Authorization = 'Bearer ' + accessToken;
	}
	// limit,
	// tags, (picker i umbraco component, duplicate existing tag picker)
	// rootId

	const { href } = getApiUrl(`news/GetNews${tags ? `?tags=${tags.join(',')}&` : '?'}limit=${count}`);
	const { data: news } = await axios.get<GetTagBasedNewsResult>(href, { headers: headers });
	return news;
};
