import { getTagBasedNewsAdapter, GetTagBasedNewsResult } from 'application/adapters/tagBasedNews/tagBasedNewsAdapter';

export type TagBasedNews = {
	heading?: string;
	image?: Umbraco.Image;
	link: {
		url: string;
		target?: string;
		name?: string;
	};
	session?: string;
	kicker?: string;
	text?: string;
	date?: Date;
	updatedDate?: Date;
	id: number;
};

export const getTagBasedNews = async (hostname: string, tags?: number[] | null, count?: number): Promise<TagBasedNews[]> => {
	const tagBasedNews = await getTagBasedNewsAdapter(hostname, tags, count);
	return tagBasedNews?.length > 0 ? mapTagBasedNews(tagBasedNews) : [];
};

// map over the array of arrangements and return a new array of simplified arrangements, with some fields formatted for display.
export const mapTagBasedNews = (news: GetTagBasedNewsResult): TagBasedNews[] => {
	return news.map((newsItem) => {
		return {
			heading: newsItem.properties?.newsHeading,
			image: newsItem.properties?.image,
			link: {
				url: newsItem.properties?.canonicalUrl,
				name: newsItem.name,
			},
			kicker: newsItem.properties?.newsKicker,
			text: newsItem.properties?.newsTeaser,
			date: new Date(newsItem.properties?.newsDate),
			updatedDate: newsItem.properties?.updatedNewsDate ? new Date(newsItem.properties?.updatedNewsDate) : null,
			id: newsItem.id,
		};
	});
};
