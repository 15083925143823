import { getAgreementDocuments, getAgreementDocumentSections, GetAgreementDocumentsResult } from 'application/adapters/agreements/agreementsAdapter';
import { getCMSHostname } from 'helpers/getCMSHostname';
import logger from 'helpers/logger';
import { useEffect, useState } from 'react';
import { Agreement, SectionContentContainer, Spinner } from 'ui/components';
import styles from './AgreementDocumentViewer.module.scss';

type AgreementDocumentViewerFeatureProps = {
	documentType: 'agreementDocumentViewer';
	content: {
		documentType: 'agreementDocumentViewer';
		properties: {
			agreementItem: {
				id: number;
				culture: string;
				name: string;
				url: string;
				documentType: string;
				template: string;
				createDate: string;
				sortOrder: string;
			};
		};
	};
};
export const AgreementDocumentViewerFeature: React.VFC<AgreementDocumentViewerFeatureProps> = ({ content }) => {
	const agreementId = content.properties.agreementItem.id;

	const [agreementMetaData, setAgreementMetaData] = useState<GetAgreementDocumentsResult | null>(null);
	const { documents } = agreementMetaData || {};

	const [selectedAgreementDocumentId, setSelectedAgreementDocumentId] = useState<string | null>(null);
	const currentAgreementDocument = documents?.find((agreementDocument) => agreementDocument.id === selectedAgreementDocumentId);
	const { title: documentTitle, teaser: documentTeaser, chapters } = currentAgreementDocument || {};

	const [selectedChapterId, setSelectedChapterId] = useState<string | null>(null);
	const currentChapter = chapters?.find((chapter) => chapter.id === selectedChapterId);

	const [selectedSectionId, setSelectedSectionId] = useState<string | null>(null);
	const [contentHtml, setContentHtml] = useState<string | null>(null);

	useEffect(() => {
		getAgreementDocuments(getCMSHostname(), agreementId)
			.then((response) => {
				setAgreementMetaData(response.data);
				setSelectedAgreementDocumentId(response.data.documents?.[0]?.id);
				setSelectedChapterId(response.data.documents?.[0]?.chapters[0]?.id);
				setSelectedSectionId(response.data.documents?.[0]?.chapters[0]?.sections[0]?.id);
			})
			.catch((error) => {
				logger.error(error);
			});
	}, [agreementId]);

	useEffect(() => {
		if (!agreementMetaData || selectedSectionId == null) return;

		setContentHtml(null); // Clear contentHtml to show loading state

		getAgreementDocumentSections(getCMSHostname(), selectedSectionId)
			.then((response) => {
				setContentHtml(response.data.sectionTexts.map((sectionText) => sectionText.html).join(''));
			})
			.catch((error) => {
				logger.error(error);
			});
	}, [agreementMetaData, selectedSectionId]);

	return (
		<SectionContentContainer>
			{!agreementMetaData || !currentAgreementDocument ? (
				<></>
			) : (
				<article className={styles.documentWrapper}>
					{documentTitle ? <h2 className={styles.documentHeading}>{documentTitle}</h2> : null}
					{documentTitle ? <p className={styles.documentTeaser}>{documentTeaser}</p> : null}
					<Agreement
						chapters={chapters}
						selectedChapterValue={selectedChapterId}
						onChapterChange={(chapterId) => {
							setSelectedChapterId(chapterId);
							setSelectedSectionId(chapters?.find((chapter) => chapter.id === chapterId)?.sections[0]?.id);
						}}
						sections={currentChapter?.sections || []}
						selectedSectionValue={selectedSectionId}
						onSectionChange={(sectionId) => {
							setSelectedSectionId(sectionId);
						}}
						contentHtml={contentHtml || ''}
						expandButtonText="Vis mere"
						collapseButtonText="Vis mindre"
					/>
				</article>
			)}
		</SectionContentContainer>
	);
};
