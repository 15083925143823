import axios from 'axios';
import { getAccessTokenBrowser } from 'helpers/auth';
import { getApiUrl } from 'helpers/getApiUrl';

export type GetArrangementsResult = Array<{
	id?: number | null;
	title?: string | null;
	description?: string | null;
	series: {
		id?: number | null;
		name?: string | null;
	} | null;
	categories?: Array<{
		id?: number | null;
		name?: string | null;
		label?: string | null;
	}> | null;
	location?: {
		id?: number | null;
		title?: string | null;
		type?: string | null;
		address?: {
			street?: string | null;
			postNumber?: string | null;
			city?: string | null;
			country?: string | null;
		} | null;
	} | null;
	start?: string | null;
	end?: string | null;
	registrationDeadline?: string | null;
	url?: string | null;
}>;

export type RequestHeaders = {
	hostname: string;
	Authorization?: string;
};

export const getArrangementsAdapter = async (hostname: string, count = 3): Promise<GetArrangementsResult> => {
	const accessToken = await getAccessTokenBrowser();
	const headers: RequestHeaders = {
		hostname,
	};
	if (accessToken) {
		headers.Authorization = 'Bearer ' + accessToken;
	}

	const { href } = getApiUrl(`Arrangement/Relevant/${count}`);
	const { data: arrangements } = await axios.get<GetArrangementsResult>(href, { headers: headers });
	return arrangements;
};

export const getSignedUpArrangementsAdapter = async (hostname: string, count = 99): Promise<GetArrangementsResult> => {
	const accessToken = await getAccessTokenBrowser();
	const headers: RequestHeaders = {
		hostname,
	};
	if (accessToken) {
		headers.Authorization = 'Bearer ' + accessToken;
	}
	const { href } = getApiUrl(`Arrangement/Registered/${count}`);
	const { data: arrangements } = await axios.get<GetArrangementsResult>(href, { headers: headers });
	return arrangements;
};
